@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gray-text {
  color: #181818;
}

.long {
  transform: translateY(5px);
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 5rem;
  font-size: 14px;
  gap: 2px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
    gap: 10px;
    width: 100%;
  }
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.pagination .page-num:hover {
  color: #ffffff;
  background-color: #3e3e3e;
}

.pagination .active {
  color: #ffffff;
  background-color: #3e3e3e;
}

.disable {
  pointer-events: none;
  opacity: 0.6;
}

.tagli {
  opacity: 0.6;
}

tr,
td,
th {
  cursor: default;
}

a {
  cursor: pointer;
}
